import React from "react";
import { Modal, Button } from 'react-bootstrap';
import * as style1 from '../../pages/dashboard/detail.module.css';
import ToastPopup from "./ToastPopup";
import { navigate } from '@reach/router';
import { useDispatch, useSelector } from "react-redux"
import { dashboardUrl } from "../../state/dashboardViewAction"

function AcceptPopup(props) {
  const { success, succes, toggleSuccess, toggleSucces, claimAlertMessage, message, messages } = props;
  const dispatch = useDispatch()
  const userDetails = useSelector(state => state.user.userDetail);
  const hanldeSuccess = () => {
    props.agentAccept()
    // toggleSuccess()
    setTimeout(() => toggleSuccess(), 3000);
  }
  const handleRoute = () => {
    if (window.location.pathname.split("/").length !== 4) {
      dispatch(dashboardUrl(window.location.pathname));
    } else {
      let pathArray = window.location.pathname.split("/");
      pathArray.splice(2, 1);  // This modifies the array in place
      dispatch(dashboardUrl(pathArray.join("/"))); // Join the array back into a string
    }
    navigate(`/signin`)
  }
  const handleSignup = () => {
    navigate(`/signup`)
  }

  const handleConnectAgent = () => {
    navigate(`/findagent/`)
  }

  return (
    <div>
      <ToastPopup success={succes} toggleSuccess={toggleSucces} messages={messages} />
      <Modal show={success} onHide={toggleSuccess}>
        <Modal.Header closeButton className="team-popup-margin teampopup-title " >
          <Modal.Title className='form-field-label form-label-team '>{claimAlertMessage ? '' : ''}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="team-popup-margin pb-0" >
          <div>
            <div className={style1.successtext}>
              {userDetails?.agent_list?.length == 0 ?
                <>
                  <p className={style1.accepttext}>{message}</p>
                  {/* <p className={style1.accepttext}>You must Login or Sign Up to KeyWe</p> */}
                </> :
                <>
                  {claimAlertMessage ?
                    <>
                      <p className={style1.accepttext}>{message}</p>
                      <p className={style1.accepttext}>You must Login or Sign Up to KeyWe</p>
                    </>
                    :
                    <>
                      <p className={style1.accepttext}>Are you sure you want to accept this request?</p>
                    </>
                  }
                </>}
            </div>
          </div>
          {userDetails?.agent_list?.length == 0 ?
            <div className="d-flex justify-content-center">
              <Button className="connectAgentPopup" onClick={handleConnectAgent}>Connect with Agent</Button>
            </div> :
            claimAlertMessage ?
              <>
                <Button className={style1.nobutton} onClick={handleRoute}>Login</Button>
                <Button className={style1.yesbutton} onClick={handleSignup}>Sign up</Button>
              </>
              :
              <>
                <Button className={style1.nobutton} onClick={toggleSuccess}>
                  No
                </Button>
                <Button className={style1.yesbutton} onClick={hanldeSuccess}>
                  Yes
                </Button>
              </>}
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AcceptPopup