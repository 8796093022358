import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import * as style1 from '../../pages/dashboard/detail.module.css';
import { navigate } from '@reach/router';
import { useDispatch, } from "react-redux"
import { dashboardUrl } from "../../state/dashboardViewAction"
import alertImageclose from "../../../static/images/alertImageclose.png"



export default function SavedSearchPopup(props) {
  // props declation //
  const { success, toggleSuccess } = props;
  const dispatch = useDispatch()
  // redux data declaration //
  const handleRoute = () => {
    if (window.location.pathname.split("/").length !== 4) {
      dispatch(dashboardUrl(window.location.pathname));
    } else {
      let pathArray = window.location.pathname.split("/");
      pathArray.splice(2, 1);  // This modifies the array in place
      dispatch(dashboardUrl(pathArray.join("/"))); // Join the array back into a string
    }
    navigate(`/signin`)
  }
  const handleSignup = () => {
    navigate(`/signup`)
  }

  return (
    <div>
      <Modal show={success} onHide={toggleSuccess} className="modelparent" style={{ top: "25%" }}>
        <div className="PopUpBorderDiv1">
          <Modal.Header closeButton className="team-popup-margin1 teampopup-title savedSearchshare alertHeadMap" >
            <Modal.Title className='form-field-label form-label-team'><h4 className='savedSearchtitle' style={{marginBottom:"0"}}>Information</h4></Modal.Title>
            <img className='position-absolute' src={alertImageclose} alt='alertImageclose' style={{right:"15px"}} onClick={toggleSuccess} />
          </Modal.Header>
          <Modal.Body className="team-popup-margin1 pb-0">
            <div>
              <div className={style1.successtextAlert}>
                <>
                  <p className={style1.accepttext} style={{ color: "black", fontSize: "16px" }}>In order to view GoKeyWe exclusive listings provided by participating brokerages, you must first <span style={{ fontFamily: "DejaVuSansBold" }}>Login</span> or <span style={{ fontFamily: "DejaVuSansBold" }}>Create an Account</span>.</p>
                </>
              </div>
            </div>
            <>
              <Button className={style1.LoginAlertbutton} onClick={handleRoute}>Login</Button>
              <Button className={style1.signupbutton1} onClick={handleSignup}>Sign up</Button>
            </>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  )
}